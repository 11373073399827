import axios from 'axios';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { setMenu } from 'redux/slices/menu';
import { fetchSettings, fetchRestSettings } from 'redux/slices/globalSettings';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setUserData } from 'redux/slices/auth';
import { Buffer } from 'buffer';
import { data as routeConfig } from 'configs/menu-config';

export const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const username = 'cheeff';
  const password = 'proConnect3003';

  const encoded = Buffer.from(username + ':' + password).toString('base64');

  const handleLogin = (code) => {
    axios
      .post(
        'https://api.mytab.uz/api/v1/get-proid-user',
        {
          code,
        },
        {
          headers: {
            Authorization: 'Basic ' + encoded,
          },
        }
      )
      .then(({ data }) => {
        const user = {
          fullName: data.user.user.firstname + ' ' + data.user.user.lastname,
          role: data.user.user.role,
          urls: routeConfig[data.user.user.role],
          img: data.user.user.img,
          token: data.user.access_token,
          email: data.user.user.email,
          id: data.user.user.id,
          shop_id: data.user.user?.shop?.id,
        };

        if (user.role === 'waiter') {
          dispatch(
            setMenu({
              icon: 'user',
              id: 'orders-board',
              name: 'my.orders',
              url: 'waiter/orders-board',
            })
          );
        }
        if (user.role === 'user') {
          notification.error({
            message: t('ERROR_101'),
          });
          navigate("/login")
          return;
        }
        localStorage.setItem('token', data.user.access_token);
        dispatch(setUserData(user));
        if (user.role === 'admin') {
          dispatch(fetchSettings());
        } else {
          dispatch(fetchRestSettings());
        }

        navigate("/")
      })
      .catch(() => {
        navigate("/login")
      })
  };

  return {
    handleLogin,
  };
};
